<template>
	<div class="animated fadeIn">
		<b-card :title="'Edit Dispatch (' + form.dispatchId + ')'"
			sub-title="Manages the update of existing dispatches for all companies">
			<br />
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								SOURCE AND DESTINATION
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>SOURCE</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Company:" label-for="Source Company">
											<v-select name="Source Company" class="style-chooser" label="text"
												:options="sourceCompanyOptions" :reduce="(company) => company.value"
												v-model="selSourceCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Company')" class="help-block">
												{{ errors.first('Source Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Storage Location:" label-for="Source Storage Location">
											<v-select name="Source Storage Location" class="style-chooser" label="text"
												:options="sourceLocationOptions" :reduce="(loc) => loc.value"
												v-model="selSourceLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Storage Location')" class="help-block">{{
												errors.first('Source Storage Location')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>DESTINATION</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Company:" label-for="Destination Company">
											<v-select name="Destination Company" class="style-chooser" label="text"
												:options="destinationCompanyOptions" :reduce="(company) => company.value"
												v-model="selDestinationCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Company')" class="help-block">
												{{ errors.first('Destination Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Storage Location:" label-for="Destination Storage Location">
											<v-select name="Destination Storage Location" class="style-chooser" label="text"
												:options="destinationLocationOptions" :reduce="(loc) => loc.value"
												v-model="selDestinationLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Storage Location')" class="help-block">{{
												errors.first('Destination Storage Location')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<em class="icon-info"></em>&nbsp;
										<strong>REMARKS</strong>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Notes:" label-for="notes" description>
											<b-form-textarea name="Notes" type="text" v-model="form.notes" maxlength="200"
												v-validate="{
													required: false,
													regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
												}" :rows="3" placeholder="Notes"></b-form-textarea>
											<span v-show="errors.has('Notes')" class="help-block">{{
												errors.first('Notes')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion3 variant="primary"
								class="text-sm-left accordion-title">TRANSPORTATION</b-btn>
						</b-card-header>
						<b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="6" md="6" sm="12">
										<b-form-group label="Plate No/CR/OR:" label-for="Plate No">
											<v-select class="style-chooser" name="Plate No" label="text"
												:options="allTransportationsOptions"
												:reduce="(selTransportation) => selTransportation.value"
												v-validate="'selectRequired'" v-model="selTransportation" />
											<span v-show="errors.has('Plate No')" class="help-block">{{
												errors.first('Plate No')
											}}</span>
										</b-form-group>
									</b-col>

									<b-col lg="6" md="6" sm="12" v-if="
										selTransportation.name == ' - Others - ' ? false : true
									">
										<b-form-group label="Company:" label-for="company">
											<b-form-input name="Company" type="text" v-model="selTransportation.company"
												maxlength="50" readonly></b-form-input>
										</b-form-group>
									</b-col>
									<b-col lg="6" md="6" sm="12" v-else>
										<b-form-group label="Company:" label-for="company">
											<b-form-input id="company" name="Company" type="text"
												v-model.trim="form.newTransportationToAdd.company" v-validate="'required'"
												maxlength="50"></b-form-input>
											<span v-show="errors.has('Company')" class="help-block">{{
												errors.first('Company')
											}}</span>
										</b-form-group>
										<b-form-group label="Plate No/CR/OR" label-for="plateNumber">
											<b-form-input id="plateNumber" name="Plate Number" type="text"
												v-model.trim="form.newTransportationToAdd.plateNo" v-validate="{
													required: true,
													regex: /^[a-zA-Z0-9_ -]{6,12}$/,
												}" maxlength="12" />
											<span v-show="errors.has('Plate Number')" class="help-block">{{
												errors.first('Plate Number')
											}}</span>
										</b-form-group>
									</b-col>

									<b-col sm="6">
										<b-form-group label="Driver:" label-for="driver">
											<b-form-input name="Driver" type="text" v-model="form.driver.name"
												maxlength="50" v-validate="{
													required: true,
													regex: /^([A-Za-z\s-]{1,50})$/,
												}"></b-form-input>
											<span v-show="errors.has('Driver')" class="help-block">{{
												errors.first('Driver')
											}}</span>
										</b-form-group>

										<b-form-group label="Drivers License" label-for="drivers-license"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="drivers-license" placeholder="Choose image"
												ref="dirvers-lincense-file-input" accept="image/png"
												@change="onSelectDriversLicense($event)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="6">
										<b-form-group label="Assistant/s:" label-for="assistants">
											<b-form-input name="Assistants" type="text" v-model="form.driver.assistants"
												maxlength="50" v-validate="{
													required: false,
													regex: /^([A-Za-z\s-]{1,50})$/,
												}"></b-form-input>
											<span v-show="errors.has('Assistants')" class="help-block">{{
												errors.first('Assistants')
											}}</span>
										</b-form-group>

										<!-- Image Preview -->
										<div v-if="driversLicense.url">
											<b-card overlay :img-src="driversLicense.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDriversLicense()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploading.uploadStatus === 'uploading'"
												:value="uploading.percentage" :max="uploading.filename" variant="success"
												height="8px" animated />
										</div>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1" v-show="!isSuperAdmin || (isSuperAdmin && !isEmptyAssets)">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion5 variant="primary" class="text-sm-left">
								ASSETS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<div v-show="showAssetPaneContent">
									<b-row class="my-12">
										<b-col sm="6">
											<AssetListTable :selAssetIds="form.assets" />
										</b-col>
										<b-col sm="6">
											<SelectedAssetListTable @onAddSelAssets="addSelAssets"
												@onDeleteSelAssets="deleteSelAssets" />
										</b-col>
									</b-row>
								</div>

								<div v-show="!showAssetPaneContent">
									<b-row>
										<b-col sm="12">
											<i class="icon-direction"></i>
											The selected source company
											<b>{{ selSourceCompany.name }}</b>
											has no associated assets to dispatch. Please select a
											different source company with associated assets to proceed
											with scheduling a dispatch.
										</b-col>
									</b-row>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToDispatchMain">
						Cancel
					</b-button>
					&nbsp;&nbsp;
					<b-button variant="primary" @click="handleOk">Save</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>

		<b-modal ref="updateSourceRef" title="Update on Source" @cancel="cancelSourceLocationChange"
			@ok="proceedSourceLocationChange" :no-close-on-backdrop="true">
			<div class="d-block text-center">
				<p>
					There are already selected assets for this source company and
					location. By proceeding with the update, the existing selected asset
					will be reset. Do you want to proceed?
				</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
// Component
import SelectedAssetListTable from './SelectedAssetListTable';
import AssetListTable from './AssetListTable';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { ArrayUtil } from '@/utils/arrayUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';
import { LocationUtil } from '@/utils/locationUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'edit-dispatch',
	components: {
		AssetListTable,
		SelectedAssetListTable,
		Loading,
		DateRangePicker,
	},
	data() {
		return {
			form: {
				id: '',
				dispatchId: '',
				source: {},
				destination: {},
				sourceScanners: [],
				destinationScanners: [],
				transportation: {
					plateNo: '',
					company: '',
					companyId: '',
				},
				driver: {
					name: '',
					licenseUrl: '',
					assistants: '',
				},
				newTransportationToAdd: {},
				assets: [],
				inputAssetLog: config.dispatchInputAssetLogDefaultValue,
				status: 'Draft',
				notes: '',
				fromInactiveNode: 'false',
				toInactiveNode: 'false',
				creationSource: '',
			},

			showFirstPane: true,
			isEmptyAssets: false,
			showAssetPaneContent: true,

			// For syncing assets deleted
			assetsToDelete: [],

			driversLicense: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			sourceCompanyOptions: [],
			destinationCompanyOptions: [],
			sourceLocationOptions: [],
			destinationLocationOptions: [],

			selSourceCompany: config.companyDefaultValue,
			selDestinationCompany: config.companyDefaultValue,
			selSourceLocation: config.storageLocationDefaultValue,
			selDestinationLocation: config.storageLocationDefaultValue,
			selTransportation: config.transportationDefaultValue,
			selDispatch: {},

			// Params from Dispatch Management
			params: {},
			allTransportationsOptions: [],
			allAssetTypesOptions: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},
			allDispatchesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);
		},

		selSourceCompany: function () {
			this.onChangeSourceCompany();
		},

		selSourceLocation: function () {
			this.onChangeSourceLocation();

			if (this.selSourceLocation) {
				// retrieve the associated assets on the selected company and location
				// and display them on the Asset List Table
				let params = {
					selSourceLocation: this.selSourceLocation,
					allAssetTypesOptions: this.allAssetTypesOptions,
					allDispatchesObj: this.allDispatchesObj,
				};
				EventBus.$emit('onChangeSelSourceLocation', params);
			}

			let locId = this.form.source.storageLocationId;
			if (locId) {
				let loc = this.allStorageLocationsObj[locId];
				let sourceLocation = DropDownItemsUtil.getStorageLocationItem(loc);
				this.form['oldSourceLocation'] = sourceLocation;
				this.$store.commit('SET_CURR_DISPATCH', this.form);
			}
		},

		selDestinationCompany: function () {
			this.onChangeDestinationCompany();
		},

		selDestinationLocation: function () {
			let locId = this.form.destination.storageLocationId;
			if (locId) {
				let loc = this.allStorageLocationsObj[locId];
				let destinationLocation = DropDownItemsUtil.getStorageLocationItem(loc);
				this.form['oldDestinationLocation'] = destinationLocation;
				this.$store.commit('SET_CURR_DISPATCH', this.form);
			}
		},

		selTransportation: function (value) {
			if (value.name === ' - Others - ') {
				this.form.newTransportationToAdd = {
					company: '', // required
					companyId: '',
					createdBy: this.loggedUser.emailAddress,
					dateCreated: DateUtil.getCurrentTimestamp(),
					dateUpdated: null,
					description: null,
					id: null,
					isActive: 'true',
					plateNo: '', // required
					updatedBy: null,
				};
			} else {
				this.form.newTransportationToAdd = {};
			}
		},
	},
	mounted() {
		// Don't initiate data retrieval when the account is not authenticated
		if (!this.$store.getters.isAuthenticated) {
			return;
		}

		// show loading indicator
		this.isLoading = true;

		setTimeout(() => {
			// init parameters
			this.params = this.$store.getters.dispatchParams;

			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
			this.allConnectionsObj = this.params.allConnectionsObj;
			this.allUsersObj = this.params.allUsersObj;
			this.allTransportationsObj = this.params.allTransportationsObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;
			this.allDispatchesObj = this.params.allDispatchesObj;

			// reset options
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);
			this.allTransportationsOptions =
				DropDownItemsUtil.retrieveTranportationItems(
					this.allTransportationsObj
				);
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypeItems(
				this.allAssetTypesObj
			);

			this.selDispatch = this.$store.getters.currDispatch;
			this.isEmptyAssets = this.selDispatch.assets.length == 0 ? true : false;

			// reset all source and destination fields
			this.resetSourceStorageLocationOptions();
			this.resetDestinationCompanyOptions();
			this.resetDestinationStorageLocationOptions();

			this.onReset();
		}, config.timeout);

		// hide loading indicator
		this.isLoading = false;
	},
	methods: {
		resetSourceStorageLocationOptions() {
			this.sourceLocationOptions = [];
			this.sourceLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selSourceLocation = config.storageLocationDefaultValue;
		},
		resetDestinationCompanyOptions() {
			this.destinationCompanyOptions = [];
			this.destinationCompanyOptions.push({
				value: config.companyDefaultValue,
				text: ' - Please select - ',
			});

			this.selDestinationCompany = config.companyDefaultValue;
		},
		resetDestinationStorageLocationOptions() {
			this.destinationLocationOptions = [];
			this.destinationLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selDestinationLocation = config.storageLocationDefaultValue;
		},

		onChangeSourceCompany() {
			// reset all source and destination fields
			this.resetSourceStorageLocationOptions();
			this.resetDestinationCompanyOptions();
			this.resetDestinationStorageLocationOptions();

			if (this.selSourceCompany !== config.companyDefaultValue) {
				// retrieve the source storage locations
				this.sourceLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selSourceCompany.id,
						this.selSourceCompany.id
					);

				// pre-select the single option value
				if (this.sourceLocationOptions.length == 2) {
					this.selSourceLocation = this.sourceLocationOptions[1].value;
				}

				let isInactiveCompany = DispatchUtil.isInactiveCompany(
					this.allCompaniesObj[this.selSourceCompany.id]
				);

				// Update dispatch form
				this.form.fromInactiveNode = isInactiveCompany;

				if (isInactiveCompany === 'true') {
					// retrieve the connected destination companies
					this.destinationCompanyOptions =
						DropDownItemsUtil.retrieveConnectedCompanyItems(
							{},
							this.loggedUserCompany
						);
				} else {
					// retrieve the connected destination companies
					this.destinationCompanyOptions =
						DropDownItemsUtil.retrieveConnectedCompanyItems(
							this.allConnectionsObj,
							this.selSourceCompany
						);
				}

				// update the list of source scanners based on the selected source company
				this.retrieveScanners();
			}

			if (this.form.source.companyId) {
				let sourceCompany = this.allCompaniesObj[this.form.source.companyId];
				this.form['oldSourceCompany'] =
					DropDownItemsUtil.getCompanyItem(sourceCompany);
				this.$store.commit('SET_CURR_DISPATCH', this.form);
			}

			if (
				this.form.assets.length > 0 &&
				this.selSourceCompany.id !== this.form.oldSourceCompany.id
			) {
				this.showConfirmSourceLocationDialog();
			}
		},
		onChangeSourceLocation() {
			if (
				this.form.assets.length > 0 &&
				this.selSourceLocation.id !== this.form.oldSourceLocation.id
			) {
				this.showConfirmSourceLocationDialog();
			}
		},
		onChangeDestinationCompany() {
			// retrieve the destination storage locations
			this.resetDestinationStorageLocationOptions();

			if (this.selDestinationCompany !== config.companyDefaultValue) {
				if (this.selSourceCompany.isActive !== 'true') {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationOfCurrCompany(
							this.selDestinationCompany.id,
							this.allStorageLocationsObj
						);
				} else {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
							this.allConnectionsObj,
							this.allStorageLocationsObj,
							this.selSourceCompany.id,
							this.selDestinationCompany.id
						);
				}

				// pre-select the single option value
				if (this.destinationLocationOptions.length == 2) {
					this.selDestinationLocation =
						this.destinationLocationOptions[1].value;
				}

				this.form.toInactiveNode = DispatchUtil.isInactiveCompany(
					this.allCompaniesObj[this.selDestinationCompany.id]
				);

				// update the list of source scanners based on the selected source company
				this.retrieveScanners();
			}

			if (this.form.destination.companyId) {
				let destinationCompany =
					this.allCompaniesObj[this.form.destination.companyId];
				this.form['oldDestinationCompany'] =
					DropDownItemsUtil.getCompanyItem(destinationCompany);
				this.$store.commit('SET_CURR_DISPATCH', this.form);
			}
		},
		showConfirmSourceLocationDialog() {
			this.$refs.updateSourceRef.show();
		},
		cancelSourceLocationChange() {
			this.$refs.updateSourceRef.hide();
			let dispatch = this.$store.state.dispatches.currDispatch;

			if (dispatch.oldSourceCompany) {
				this.selSourceCompany = DropDownItemsUtil.getCompanyItem(
					dispatch.oldSourceCompany
				);
				this.form.fromInactiveNode =
					dispatch.oldSourceCompany.isActive === 'false' ? 'true' : 'false';
			}

			if (dispatch.oldSourceLocation) {
				this.selSourceLocation = DropDownItemsUtil.getStorageLocationItem(
					dispatch.oldSourceLocation
				);
			}

			if (dispatch.oldDestinationCompany) {
				this.selDestinationCompany = DropDownItemsUtil.getCompanyItem(
					dispatch.oldDestinationCompany
				);
				this.form.toInactiveNode =
					dispatch.oldDestinationCompany.isActive === 'false'
						? 'true'
						: 'false';
			}

			if (dispatch.oldDestinationLocation) {
				this.selDestinationLocation = DropDownItemsUtil.getStorageLocationItem(
					dispatch.oldDestinationLocation
				);
			}

			this.form['oldSourceCompany'] = this.selSourceCompany;
			this.form['oldSourceLocation'] = this.selSourceLocation;
			this.form['oldDestinationCompany'] = this.selDestinationCompany;
			this.form['oldDestinationLocation'] = this.selDestinationLocation;

			this.updateSourceSelection();
			this.updateDestinationSelection();

			this.onResetDropDownValues(dispatch);
		},
		proceedSourceLocationChange() {
			this.$refs.updateSourceRef.hide();
			// reset asset content
			this.form.assets = [];
			EventBus.$emit('resetSelectedAssetList');

			this.updateSourceSelection();
			this.updateDestinationSelection();
		},

		retrieveScanners() {
			this.form.sourceScanners = [];

			let selCompanyId = DispatchUtil.getCompanyIdForUserOptions(
				this.selSourceCompany,
				this.selDestinationCompany
			);

			if (selCompanyId) {
				_.forEach(this.allUsersObj, (user, userId) => {
					if (user.companyId === selCompanyId) {
						this.addScannerInBackground(user);
					}
				});
			}
		},
		addScannerInBackground(user) {
			if (!_.find(this.form.sourceScanners, { id: user.id })) {
				let scanner = {
					id: user.id,
					name: user.firstName + ' ' + user.lastName,
					type: user.type,
					scannedAssets: [],
				};
				this.form.sourceScanners.push(scanner);
			}
		},

		plateNoExists(transportation) {
			let currPlateNo = '';

			let currDispatch = this.$store.getters.currDispatch;
			if (
				!_.isEmpty(currDispatch) &&
				currDispatch.transportation &&
				currDispatch.transportation.plateNo
			) {
				currPlateNo = currDispatch.transportation.plateNo;
			}

			const plateNos = _.map(this.allTransportationsObj, (o) => {
				return o.plateNo.toUpperCase();
			});

			return (
				plateNos.includes(transportation.plateNo.toUpperCase()) &&
				currPlateNo != transportation.plateNo
			);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			this.isLoading = true;

			let isValid = this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			// source and destination must not be the same
			if (
				this.selSourceCompany.id === this.selDestinationCompany.id &&
				this.selSourceLocation.id === this.selDestinationLocation.id
			) {
				this.$toaster.warning('Source and Destination should not be the same.');
				this.isLoading = false;
				return;
			}

			// for new transportation created on-the-fly
			if (this.selTransportation.name === ' - Others - ') {
				if (
					!DispatchUtil.isValidTransportation(this.form.newTransportationToAdd)
				) {
					this.$toaster.warning('Company or Plate Number is required.');
					this.isLoading = false;
					return;
				}
				if (this.plateNoExists(this.form.transportation)) {
					this.$toaster.warning('Plate number already exists.');
					this.isLoading = false;
					return;
				}
			}

			this.form = this.processDispatchForm();
			this.handleSubmit();

			// hide loading indicator
			this.isLoading = false;
		},

		processDispatchForm() {
			// Update Dispatch Fields
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);

			// update dispatch date, source, destination and transportation selection to form
			this.updateDriver();
			this.updateSourceSelection();
			this.updateDestinationSelection();
			this.updateTransportation();

			return this.form;
		},
		updateDriver() {
			this.form.driver.name = ValidationUtil.removeExcessWhiteSpace(
				this.form.driver.name
			);
			this.form.driver.assistants = ValidationUtil.removeExcessWhiteSpace(
				this.form.driver.assistants
			);
			this.form.driver.licenseUrl = this.driversLicense.url;
		},
		updateSourceSelection() {
			this.form.source = {
				company: this.selSourceCompany.name,
				companyId: this.selSourceCompany.id,
				storageLocation: this.selSourceLocation.name,
				storageLocationId: this.selSourceLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selSourceLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(this.selSourceLocation.geoaddress),
				longitude: LocationUtil.getLongitude(this.selSourceLocation.geoaddress),
			};
		},
		updateDestinationSelection() {
			this.form.destination = {
				company: this.selDestinationCompany.name,
				companyId: this.selDestinationCompany.id,
				storageLocation: this.selDestinationLocation.name,
				storageLocationId: this.selDestinationLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selDestinationLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(
					this.selDestinationLocation.geoaddress
				),
				longitude: LocationUtil.getLongitude(
					this.selDestinationLocation.geoaddress
				),
			};
		},
		updateTransportation() {
			let transportation = {};

			if (!_.isEmpty(this.form.newTransportationToAdd)) {
				transportation.plateNo = this.form.newTransportationToAdd.plateNo;
				transportation.company = this.form.newTransportationToAdd.company;
				transportation.companyId = this.form.newTransportationToAdd.companyId;
			} else {
				transportation.plateNo = this.selTransportation.name;
				transportation.company = this.selTransportation.company;
				transportation.companyId = this.selTransportation.companyId;
			}

			this.form.transportation = transportation;
		},

		async uploadDriversLicensePhoto(dispatch) {
			let image = this.driversLicense;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image, dispatch);
				if (uploadResult.name && uploadResult.url) {
					dispatch.driver.licenseUrl = uploadResult.url;
					const updateResult = await dispatchApi.saveDispatch(
						dispatch,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress('success');
					}
				}
			}
		},
		async firebaseUploadImage(image, dispatch) {
			let filename = `${dispatch.dispatchId}_${dispatch.driver.name}.png`;

			this.onUploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${image.fbStoragePath}/${filename}`);
				let task = storageRef.put(image.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		onUploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},
		onResetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},
		onRemoveDriversLicense(index) {
			this.driversLicense = {};
			this.$refs['dirvers-lincense-file-input'].reset();
		},
		onSelectDriversLicense(evt) {
			const vm = this;
			const file = evt.target.files[0];
			const url = URL.createObjectURL(file);

			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Drivers lincense photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.driversLicense = {
						url: url,
						file: file,
						fbStoragePath: 'images/driversLicenses',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		async handleSubmit() {
			let dispatchId = this.form.dispatchId;

			try {
				let { data } = await dispatchApi.saveDispatch(
					this.form,
					this.loggedUser.id
				);

				// hide loading indicator
				this.isLoading = false;
				// reset
				this.assetsToDelete = [];

				if (data.isSuccess) {
					// upload driverse license photo
					if (!_.isEmpty(this.driversLicense)) {
						await this.uploadDriversLicensePhoto(data.dispatch);
					}

					this.$toaster.success(
						`Dispatch "${dispatchId}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditDispatch', data.dispatch);
					this.addNewTransportationToStore(data.dispatch);

					// reset the state and revert to dispatch page
					this.returnToDispatchMain();
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.error(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					`Error updating dispatch ${dispatchId}. Please try again.`
				);
			}
		},
		addNewTransportationToStore(dispatch) {
			if (!_.isEmpty(dispatch.newTransportationToAdd)) {
				let newTransportations = {};
				newTransportations[dispatch.newTransportationToAdd.id] =
					dispatch.newTransportationToAdd;
				this.$store.dispatch('updateAllTransportations', newTransportations);
			}

			if (!_.isEmpty(dispatch.newCompanyToAdd)) {
				let newCompanies = {};
				newCompanies[dispatch.newCompanyToAdd.id] = dispatch.newCompanyToAdd;
				this.$store.dispatch('updateAllCompanies', newCompanies);
			}
		},
		returnToDispatchMain() {
			this.params.fromEditDispatch = true;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/dispatch' });
			} else {
				this.$router.push({ path: '/admin/admin-dispatch' });
			}
		},

		addSelAssets(selAssetsArr) {
			_.forEach(selAssetsArr, (asset) => {
				if (!this.form.assets.includes(asset.assetCode)) {
					this.form.assets.push(asset.assetCode);
					this.form.inputAssetLog['dispatch'].manual.push(asset.assetCode);
					// add assets for all source scanners
					this.form.sourceScanners.forEach((scanner) => {
						if (scanner.id === this.loggedUser.id) {
							scanner.scannedAssets.push(asset.assetCode);
						}
					});
				}
			});
		},
		deleteSelAssets(selAssetCodesArr) {
			// merge to the list of assets deleted during update
			this.assetsToDelete = _.union(this.assetsToDelete, selAssetCodesArr);

			_.forEach(selAssetCodesArr, (assetCode) => {
				if (this.form.assets.includes(assetCode)) {
					// delete from form assets and the current scanner
					this.form.assets = ArrayUtil.removeItem(this.form.assets, assetCode);

					this.form.inputAssetLog['dispatch'].manual = ArrayUtil.removeItem(
						this.form.inputAssetLog['dispatch'].manual,
						assetCode
					);

					// delete assets from all source scanners
					this.form.sourceScanners.forEach((scanner) => {
						scanner.scannedAssets = ArrayUtil.removeItem(
							scanner.scannedAssets,
							assetCode
						);
					});
				}
			});
		},

		onReset() {
			/* Reset our form values */
			let dispatch = this.selDispatch;

			// primary details
			this.form.id = dispatch.id;
			this.form.dispatchId = dispatch.dispatchId;
			this.form.source = dispatch.source;
			this.form.destination = dispatch.destination;
			this.form.status = dispatch.status;
			this.form.notes = dispatch.notes;

			// transportation
			this.form.transportation = dispatch.transportation;
			this.form.driver = dispatch.driver;
			this.form.newTransportationToAdd = dispatch.newTransportationToAdd;
			this.driversLicense = DispatchUtil.getDriverLicenseObj(this.form.driver);

			// assets and scanners
			this.form.assets = dispatch.assets;
			this.form.sourceScanners = [];
			if (dispatch.sourceScanners) {
				this.form.sourceScanners = dispatch.sourceScanners;
			}
			this.form.destinationScanners = [];
			if (dispatch.destinationScanners) {
				this.form.destinationScanners = dispatch.destinationScanners;
			}

			// timestamp
			this.form.dateCreated = dispatch.dateCreated;
			this.form.createdBy = dispatch.createdBy;
			this.form.dateActivated = dispatch.dateActivated;
			this.form.activatedBy = dispatch.activatedBy;
			this.form.dateUpdated = dispatch.dateUpdated;
			this.form.updatedBy = dispatch.updatedBy;
			this.form.dateDeployed = dispatch.dateDeployed;
			this.form.deployedBy = dispatch.deployedBy;
			this.form.dateCancelled = dispatch.dateCancelled;
			this.form.cancelledBy = dispatch.cancelledBy;
			this.form.dateReceived = dispatch.dateReceived;
			this.form.receivedBy = dispatch.receivedBy;

			// others
			this.form.creationSource = dispatch.creationSource;
			this.form.fromInactiveNode = dispatch.fromInactiveNode;
			this.form.toInactiveNode = dispatch.toInactiveNode;
			this.form.inputAssetLog = dispatch.inputAssetLog;

			if (dispatch.validationWarnings) {
				this.form.validationWarnings = dispatch.validationWarnings;
			}

			this.onResetDropDownValues(dispatch);

			// Assign the default previous values as well
			this.form.oldSourceCompany = this.selSourceCompany;
			this.form.oldSourceLocation = this.selSourceLocation;
			this.form.oldDestinationCompany = this.selDestinationCompany;
			this.form.oldDestinationLocation = this.selDestinationLocation;

			let param = {
				companyId: this.selSourceCompany.id,
				storageLocationId: this.selSourceLocation.id,
				selAssetIds: dispatch.assets,
			};

			EventBus.$emit('initSelectedAssets', param);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		onResetDropDownValues(dispatch) {
			// SOURCE
			let source = dispatch.source;
			let sourceCompany = this.allCompaniesObj[source.companyId];
			this.selSourceCompany = DropDownItemsUtil.getCompanyItem(sourceCompany);
			this.onChangeSourceCompany();

			let sourceLocation =
				this.allStorageLocationsObj[source.storageLocationId];
			this.selSourceLocation =
				DropDownItemsUtil.getStorageLocationItem(sourceLocation);

			// DESTINATION
			let destination = dispatch.destination;
			let destinationCompany = this.allCompaniesObj[destination.companyId];
			this.selDestinationCompany =
				DropDownItemsUtil.getCompanyItem(destinationCompany);
			this.onChangeDestinationCompany();

			let destinationLocation =
				this.allStorageLocationsObj[destination.storageLocationId];
			this.selDestinationLocation =
				DropDownItemsUtil.getStorageLocationItem(destinationLocation);

			this.selTransportation = DropDownItemsUtil.getTransportationItem(
				dispatch.transportation
			);
		},
	},
};
</script>

<style scoped>
.accordion-title {
	color: white;
}
</style>
